@tailwind base;
@tailwind components;
@tailwind utilities;

/* * width */ 
::-webkit-scrollbar {
    width: 5px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}



/* Dark Mode Specific Colors */
:root{
    --global-primary-bg: #080808;
    --global-primary-bg-tr: rgba(8, 8, 8, 0.9);
    --global-secondary-bg: #141414;
    --global-tertiary-bg: #222222;
    --global-div: #141414;
    --global-div-tr: rgba(20, 20, 20, 0.5);
    --global-border: rgba(245, 245, 245, 0.1);
    --global-text: #e8e8e8;
    --global-text-muted: #696969;
    --global-card-bg: #181818;
    --global-card-shadow: rgba(0, 0, 0, 0.6);
    --global-primary-skeleton: rgba(85, 85, 85, 0.1);
    --global-secondary-skeleton: rgba(85, 85, 85, 0.3);
    --global-button-bg: #202020;
    --global-button-hover-bg: #292929;
    --global-button-shadow: rgba(0, 0, 0, 0.6);
    --global-button-text: #ebebeb;
    --global-genre-button-bg: #222222;
    --global-shadow: rgba(255, 255, 255, 0.08);
    --global-card-title-bg: #151515;
   
  }
  
  /* Basic app styles */
  body {
    font-family:
      ui-sans-serif,
      system-ui,
      sans-serif,
      Apple Color Emoji,
      Segoe UI Emoji,
      Segoe UI Symbol,
      Noto Color Emoji;
    font-feature-settings: normal;
    font-variation-settings: normal;
    margin: 0;
    max-width: 105rem;
    margin: auto;
    background-color: var(--global-primary-bg);
    color: var(--global-text);
    transition: 0.2s ease;
  }
  /* Responsive styles */
  @media (max-width: 500px) {
    body {
      padding: 0rem 0.5rem 0.5rem 0.5rem;
    }
  }
  /* Selection styles */
  ::selection {
    background-color: var(--primary-accent-bg);
    color: var(--primary-accent);
  }
  