.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color:; dark background */
    color: white; /* text color */
  }
  
  .loader-image {
    width: 150px; /* adjust size as needed */
    scale: 1.3;
    margin-bottom: 0px;

  }
  
  .loader-text {
    margin-top: 0px;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .loader-bar {
    width: 250px;
    height: 4px;
    background-color: #000011a6;
    border-radius: 5px;
    opacity: 1;
    overflow: hidden;
    position: relative;
  }
  
  .loader-progress {
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: white;
    animation: slide 1s infinite;
  }
  
  @keyframes slide {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  